import { VIRUS_STATUS } from "src/common/common.constants";
import { getFile, getFiles } from "src/documents/documents.resource";

export function getVirusStatusOnDocs(docs) {
  const virusStatuses = docs.reduce(
    (acc, doc) => {
      if (doc.status === VIRUS_STATUS.STATUS_EMPTY) {
        acc.docsToScan.push(doc); // existing files, don't do anything
      } else if (doc.status === VIRUS_STATUS.STATUS_SCANNING || doc.status === VIRUS_STATUS.STATUS_NOT_SCANNED) {
        acc.docsInProgress.push(doc); // currently being scanned
      } else if (doc.virus_scan_passed === false) {
        acc.infectedDocs.push(doc);
      }
      return acc;
    },
    { docsToScan: [], docsInProgress: [], infectedDocs: [] }
  );

  return virusStatuses;
}

export function isFileSafeToOpen(fileId, downloadOrPrint = "download") {
  return new Promise((resolve, reject) => {
    getFile(fileId, false, downloadOrPrint).subscribe((updatedFile) => {
      const virusStatuses = getVirusStatusOnDocs([updatedFile]);
      resolve({
        trust: virusStatuses.docsInProgress.length === 0 && virusStatuses.infectedDocs.length === 0,
        virusStatuses,
        file: updatedFile,
      });
    }, reject);
  });
}

export function areFilesSafeToOpen(fileIds, downloadOrPrint = "download") {
  return new Promise((resolve, reject) => {
    getFiles(fileIds, downloadOrPrint).subscribe((updatedFiles) => {
      const virusStatuses = getVirusStatusOnDocs(updatedFiles);
      resolve({
        trust: virusStatuses.docsInProgress.length === 0 && virusStatuses.infectedDocs.length === 0,
        virusStatuses,
        updatedFiles,
      });
    }, reject);
  });
}
