import React from "react";
import ReactDOMClient from "react-dom/client";
import PropTypes from "prop-types";
import { handleError } from "src/handle-error.helper";
import singleSpaReact from "single-spa-react";
import { Scoped, k } from "kremling";
import Disposable from "react-disposable-decorator";
import { ErrorBoundary } from "error-logging!sofe";
import { fromEvent } from "rxjs";
import { CpLoader } from "canopy-styleguide!sofe";
import { Modal as Dialog } from "react-disposable-modal";

import PdfPage from "src/documents/preview/pdf-page.component";
import { isPdf, isImage } from "src/common/mime-types.helpers";
import { getDocumentPreview } from "src/documents/documents.resource";

@Disposable
export default class SimpleDocumentPreview extends React.PureComponent {
  static propTypes = {
    documentId: PropTypes.string,
    document: PropTypes.shape({
      title: PropTypes.string.isRequired,
      pages: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.string.isRequired,
    }),
    handleClosePreview: PropTypes.func.isRequired,
    actions: PropTypes.arrayOf(PropTypes.node),
  };
  constructor(props) {
    super(props);

    if (!props.documentId && !props.document) {
      throw new Error("SimpleDocumentPreview requires a documentId or a document object as props.");
    }

    this.state = {
      docIsImage: null,
      docIsPdf: null,
      loadedPageIndex: 0,
      loading: true,
      pages: [],
      title: "",
    };
  }

  componentDidMount() {
    if (this.props.documentId) {
      this.props.cancelWhenUnmounted(
        getDocumentPreview(this.props.documentId).subscribe(this.unwrapDocument, handleError)
      );
    } else {
      this.unwrapDocument(this.props.document);
    }
    document.body.style.overflow = "hidden";

    this.props.cancelWhenUnmounted(fromEvent(document, "keydown").subscribe(this.closeOnEscKey, handleError));
  }

  componentWillUnmount() {
    document.body.style.overflow = "";
  }

  closeOnEscKey = (e) => {
    if (e.key === "Escape") {
      this.props.handleClosePreview(e);
    }
  };

  unwrapDocument = (document) => {
    const { title, pages, type } = document;
    const docIsImage = isImage(type);
    const docIsPdf = isPdf(type);
    this.setState({ title, pages, docIsImage, docIsPdf });
  };

  incLoadedPageIndex = () => {
    this.setState({
      loadedPageIndex: this.state.loadedPageIndex + 1,
      loading: false,
    });
  };

  getDocPreviews = () => {
    const { pages, docIsImage, docIsPdf, loadedPageIndex } = this.state;
    if (pages.length) {
      if (docIsImage) {
        return pages.map((page, i) => (
          <div
            key={`preview-${i}`}
            data-testid="test-docIsImage"
            className={`cps-card previewPage`}
            style={{ backgroundImage: `url(${page})` }}
          ></div>
        ));
      } else if (docIsPdf) {
        return pages.map((page, i) =>
          i <= loadedPageIndex ? (
            <PdfPage key={`preview-${i}`} src={page} onLoad={this.incLoadedPageIndex.bind(this)} />
          ) : null
        );
      } else {
        return (
          <div style={{ margin: "auto" }}>
            <span className="cps-white">Unable to display preview</span>
          </div>
        );
      }
    }
  };

  render() {
    const { actions = [], handleClosePreview = () => {} } = this.props;
    const { title } = this.state;
    return (
      <Dialog>
        <Scoped css={styles}>
          <div className={`cps-modal`}>
            <div className={`cps-modal__screen previewModal`}>
              <div className={`previewHeader cps-depth-2`}>
                <span
                  className={`cps-white cps-subheader-sm`}
                  style={{ width: "30%", display: "flex", alignItems: "center" }}
                >
                  <span className="cps-white cps-icon cps-icon-letters"></span>
                  <span className={`cps-ellipsis cps-margin-left-16`}>{title}</span>
                </span>
                <span className={styles.rightActions}>
                  {actions.length > 0 && (
                    <span className={`cps-padding-right-16 cps-margin-right-16 actionGroup`}>
                      {actions.map((action, i) => React.cloneElement(action, { key: `preview-action-${i}` }))}
                    </span>
                  )}
                  <span>
                    <button
                      type="button"
                      id="simpleDocPreviewCloseBtn"
                      onClick={handleClosePreview}
                      className="cps-btn-icon cps-link cps-padding-4"
                    >
                      <span className={`cps-white cps-icon cps-icon-close close`} />
                      <span className="cps-hide-visually">Close preview</span>
                    </button>
                  </span>
                </span>
              </div>
              <div className={`preview-empty-space previewContent`}>
                {this.getDocPreviews()}
                {this.state.loading && (
                  <div style={{ margin: "auto" }}>
                    <CpLoader size="sm" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Scoped>
      </Dialog>
    );
  }
}

const headerHeight = "50px";
const styles = k`
.previewModal {
  background: rgba(61, 70, 77, 0.95);
}
.previewHeader {
  height: ${headerHeight};
  padding: 0 24px;
  width: 100%;
  background-color: #3D464D;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightActions {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.actionGroup {
  border-right: 2px solid var(--cp-color-well-border);
}
.previewContent {
  height: calc(100vh - ${headerHeight});
  overflow: auto;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.previewPage {
  background-size: contain;
  background-repeat: no-repeat;
  width: 81.8rem;
  min-height: 105.8rem;
  margin: 0 auto;
}
.previewPage:not(:first-of-type) {
  margin-top: 28px;
}
`;

const decoratedSimpleDocumentPreview = ErrorBoundary({ featureName: "docs-ui.preview-lite" })(SimpleDocumentPreview);

const reactLifecycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: ErrorBoundary({ featureName: "docs-ui_simple-document-preview-parcel" })(
    decoratedSimpleDocumentPreview
  ),
});

const featureToggles = [];

const bootstrap = [
  () => {
    if (featureToggles.length) {
      return SystemJS.import("feature-toggles!sofe").then((ft) => ft.fetchFeatureToggles(...featureToggles));
    } else {
      return Promise.resolve();
    }
  },
  reactLifecycles.bootstrap,
];

export const SimpleDocumentPreviewParcel = {
  ...reactLifecycles,
  bootstrap,
};
