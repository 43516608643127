import React, { useContext, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { a } from "kremling";
import { debounce } from "lodash";
import { handleError } from "src/handle-error.helper";
import { CpIcon, CpLoader, CpSelectSingle, CpButton, CpEmptyState } from "canopy-styleguide!sofe";
import styles from "./content-explorer-body.styles.css";
import SaveToCanopyFilesDropdownContext from "src/documents/save-to-canopy-files-dropdown/save-to-canopy-files-dropdown.context";
import DocIcon from "src/documents/list/document/doc-icon.component";
import { getClientById, getClientsByName } from "./content-explorer.resource";

ContentExplorerBody.propTypes = {
  clientSelectEnabled: PropTypes.bool,
  clientId: PropTypes.number,
  folders: PropTypes.array,
  files: PropTypes.array,
  isSavingFile: PropTypes.bool.isRequired,
};

export default function ContentExplorerBody(props) {
  const { clientId, path, clientSelectEnabled, isSavingFile, files, folders } = props;
  const dispatch = useContext(SaveToCanopyFilesDropdownContext);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [search, setSearch] = useState("");
  const [selectedClient, setSelectedClient] = useState();
  const [clients, setClients] = useState([]);
  const [loadingClients, setLoadingClients] = useState(false);

  useEffect(() => {
    if (selectedClient && selectedClient.id !== clientId) {
      dispatch({ type: "change_client_id", payload: selectedClient.id });
    }
  }, [selectedClient, clientId, dispatch]);

  useEffect(() => {
    if (clientId) {
      setLoadingClients(true);
      const sub = getClientById(clientId).subscribe((client) => {
        setLoadingClients(false);
        setSelectedClient(client);
      }, handleError);
      return () => sub.unsubscribe();
    }
  }, [clientId]);

  //eslint-disable-next-line
  const debouncedSearch = useCallback(debounce(execSearch, 400), []);

  function execSearch(val) {
    if (val) {
      setLoadingClients(true);
      getClientsByName(val).subscribe((resp) => {
        const clients = resp.filter((r) => r.field === "name");
        setLoadingClients(false);
        setClients(clients);
      }, handleError);
    } else {
      setClients([]);
    }
  }

  function onSearchChange(val) {
    setSearch(val);
    debouncedSearch(val);
  }

  const folderText = path.length > 0 ? path[path.length - 1].name : "";

  return (
    <div className={styles.body}>
      {!!clientSelectEnabled && (
        <>
          <CpSelectSingle
            data={clients}
            searchOnChange={onSearchChange}
            placeholder="Select client"
            onChange={setSelectedClient}
            searchValue={search}
            value={selectedClient}
            contentWidth="md"
            triggerIsBlock
            className={styles.clientSelect}
            loading={loadingClients}
          />
          {path.length > 1 && !isSavingFile && (
            <CpButton
              icon="arrow-line-left"
              label="Go back to parent folder"
              aria-label="Go back to parent folder"
              btnType="tertiary"
              className="cp-ml-16 cp-mb-8"
              onClick={() => dispatch({ type: "change_folder_id", payload: path[path.length - 2].file_id })}
            >
              <div className={a("cps-ellipsis", styles.title)}>{folderText}</div>
            </CpButton>
          )}
        </>
      )}
      <div className={styles.contentContainer}>{renderContent()}</div>
    </div>
  );

  function renderContent() {
    if (folders && files) {
      if (folders.length > 0 || files.length > 0) {
        return (
          <ul className={styles.menu} role="menu">
            {!isSavingFile && (
              <div onMouseLeave={() => setFocusedIndex(-1)}>
                {folders.map((folder, index) => (
                  <li
                    key={folder.id}
                    className={a(styles.item, styles.clickable).m(styles.focused, focusedIndex === index)}
                    tabIndex="0"
                    role="option"
                    onMouseEnter={() => setFocusedIndex(index)}
                    onFocus={() => setFocusedIndex(index)}
                    onClick={() => dispatch({ type: "change_folder_id", payload: folder.id })}
                    onKeyDown={(e) => handleItemKeyDown(e, folder)}
                    onBlur={() => setFocusedIndex(-1)}
                  >
                    <div className={styles.left} title={folder.name}>
                      <CpIcon name="folder" />
                      <div className={a("cps-ellipsis", styles.name)}>{folder.name}</div>
                    </div>
                    <div className={styles.right}>{focusedIndex === index && <CpIcon name="caret-large-right" />}</div>
                  </li>
                ))}
              </div>
            )}
            <div className={styles.filesSection}>
              {isSavingFile &&
                folders.map((folder) => (
                  <li key={folder.id} className={styles.item}>
                    <div className={styles.left} title={folder.name}>
                      <CpIcon name="folder" />
                      <div className={a("cps-ellipsis", styles.name)}>{folder.name}</div>
                    </div>
                  </li>
                ))}
              {files.map((file) => (
                <li key={file.id} className={styles.item}>
                  <div className={styles.left} title={file.name}>
                    <DocIcon doc={file} />
                    <div className={a("cps-ellipsis", styles.name)}>{file.name}</div>
                  </div>
                </li>
              ))}
            </div>
          </ul>
        );
      } else {
        return <div className={styles.noContent}>This folder is empty</div>;
      }
    } else if (!loadingClients && !selectedClient && clientSelectEnabled) {
      return (
        <CpEmptyState img="es_clients_search" text="Select a client" subText="Save this file to your clients account" />
      );
    } else {
      return (
        <div className={styles.loading}>
          <CpLoader />
        </div>
      );
    }
  }

  function handleItemKeyDown(e, folder) {
    if (e.key === "Enter") {
      dispatch({ type: "change_folder_id", payload: folder.id });
    }
  }
}
